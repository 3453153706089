@import "~nouislider/dist/nouislider.css";

.noUi-target {
    border: 0;
    box-shadow: none;
    background: none;
    border-radius: 0;
    color: $form-range-thumb-bg;
}

.noUi-horizontal {
    height: add($form-range-track-height, 1rem);
    padding: 0.5rem 0;
}

.noUi-base {
    background: $form-range-track-bg;
    border-radius: $form-range-track-border-radius;
}

.noUi-handle {
    width: $form-range-thumb-width;
    height: $form-range-thumb-height;
    border: $form-range-thumb-border;
    box-shadow: $form-range-thumb-box-shadow;
    border-radius: $form-range-thumb-border-radius;
    background: currentColor;
    outline: 0;

    &:before,
    &:after {
        content: none;
    }

    .noUi-horizontal & {
        width: $form-range-thumb-width;
        height: $form-range-thumb-height;
        top: (-$form-range-thumb-height * 0.5);
        right: (-$form-range-thumb-height * 0.5);
        margin: 1px 1px 0 0;
    }

    &.noUi-active,
    &:focus {
        box-shadow: $form-range-thumb-focus-box-shadow;
    }
}

.noUi-connect {
    background: currentColor;
}
