@import "./variables";

@import "~@tabler/core/src/scss/tabler.scss";
@import "~@tabler/core/src/scss/vendor/_litepicker.scss";
@import "~@tabler/core/src/scss/vendor/_tom-select.scss";
@import "~@tabler/core/src/scss/vendor/_apexcharts.scss";
@import "~intl-tel-input/build/css/intlTelInput.css";

@import "./components/table";
@import "./components/scheduler";
@import "./components/timesheet";
@import "./nouislider.scss";

@import "./vendors/intl-tel-input";

.dropdown {
    &-menu {
        &.show {
            transform: translate(0px, 8px) !important;
        }
        &-demo {
            &.show {
                transform: translate(1142px, 178px) !important;
            }
        }
        &.filter {
            &.show {
                transform: translate(0px, 39px) !important;
                width: max-content;
            }
        }
    }
    &-date{
        padding-left: 0.75rem !important;
    }
}


.input{
    &-calender{
        width: 100%;
    }
}


// modal select week

.btn-cal:not(:disabled) {
    cursor: pointer;
}

.btn-cal  {
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border-radius: 0.25rem;
}

.primary-box {
    border-color: #007bff;
    color: #007bff;
}

.primary-box tbody>tr:hover {
    background-color: #007bff;
    color: #fff;
}


.primary-box span:hover {
    background-color: #007bff;
    color: #fff;
}

.green {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.green-box {
    border-color: #28a745;
    color: #28a745
}

.red {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.red-box {
    border-color: #dc3545;
    color: #dc3545;
}

.yellow {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.yellow-box {
    border-color: #ffc107;
    color: #ffc107
}

.info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.info-box {
    border-color: #17a2b8;
}


.light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.light-box {
    border-color: #f8f9fa;
}

.dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.dark-box {
    border-color: #343a40;
}

.flex {
    display: flex;
    align-items: center;
}

.box {
    border: 1px solid;
    width: 274px;
    border-radius: 5px;
    z-index: 100;
    position: absolute;
    background-color: #fff;
    user-select: none;
}

.display {
    display: none;
}

.navbar-cal {
    width: 274px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid;
}

.item {
    padding: 6px;
    cursor: pointer;
}

.arrow {
    width: 1em; 
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    overflow: hidden;
}

.icon-cal {
    width: 1.0634765625em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    overflow: hidden;
    margin: 0 4px;
}

.row-cal {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 274px;
    justify-items: center;
    align-items: center;
    cursor: pointer;
}

.row-cal td {
    padding: 8px 5px 8px 5px;
}

.highlighted {
    color: #fff;
    background-color: #007bff;
}

.unset-button {
    all: unset;
}

.week-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    margin: 8px;
}

.invalid-feedback-custom{
    width: 100%;
    margin-top: 0.25rem;
    font-size: 85.71428571%;
    color: #d63939;
}


.status{
    height: inherit !important;
    padding: 5px 9.75px !important;
    font-size: 0.7rem !important;
}

.pac-container {
    z-index: 10000 !important;
}